import { Component, Input } from '@angular/core';
import { FeedbackService } from "../model/feedback.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FeedbackRequest } from "../model/feedback-api";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss',
    standalone: false
})
export class FeedbackComponent {
  @Input() title = ''

  @Input() set maxScore(value: number) {
    this.points = this.service.createPoints(value)
  }

  @Input() commentVisible: boolean = false

  points = this.service.createPoints(6)
  enabled: boolean = false
  processing: boolean = false

  fg: FormGroup = this.fb.group({
    comment: this.fb.control(''),
    score: this.fb.control('', [Validators.required])
  })

  private url!: string


  constructor(private service: FeedbackService, private fb: FormBuilder, private router: Router, private translate: TranslateService) {
  }

  ngOnInit() {
    this.url = this.router.url
    this.enabled = this.service.showFeedback(this.url)
  }


  save() {
    if (!this.fg.valid) return
    this.processing = true
    this.service.getPosition().then(pos => {
      let values = this.fg.value
      let request = new FeedbackRequest(
        values.score,
        values.comment,
        this.url,
        window.navigator.userAgent,
        this.translate.currentLang,
        pos.lat,
        pos.lon
      )

      this.service.feedback(request).subscribe({
        next: value => this.enabled = false,
        complete: () => this.processing = false
      })
    })
  }





}
